import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {GlobalState} from "~reducers";
import {
  ConversationsListSearchParamsType,
  messengerAT,
  messengerNameSpacer,
} from "~reducers/businessOwner/messenger";

export const useConversationsListSearchParams = () => {
  const dispatch = useDispatch();
  const {page, q, unread, isEscalated} = useSelector(
    (state: GlobalState) => state.businessOwner.messenger.conversationsListSearchParams
  );

  const setConversationsListSearchParams = useCallback(
    (params: Partial<ConversationsListSearchParamsType>) => {
      dispatch({
        type: messengerNameSpacer(messengerAT.SET_CONVERSATIONS_LIST_SEARCH_PARAMS),
        payload: params,
      });
    },
    [dispatch]
  );

  const setConversationsListSearchParamsToDefault = useCallback(() => {
    dispatch({
      type: messengerNameSpacer(messengerAT.SET_CONVERSATIONS_LIST_SEARCH_PARAMS),
      payload: {
        page: 1,
        q: "",
        unread: false,
        isEscalated: false,
      },
    });
  }, [dispatch]);

  return {
    page,
    q,
    unread,
    setConversationsListSearchParams,
    setConversationsListSearchParamsToDefault,
    isEscalated,
  };
};
