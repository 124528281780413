import React from "react";

import {BaseBillingSuspensionModal} from "./BaseBillingSuspensionModal";

const RESTORE_ACCESS_LINK =
  "mailto:billing@trycents.com?subject=Suspended Account - Restore Access";

export const BillingSuspension90DaysBlockingModal = () => {
  return (
    <BaseBillingSuspensionModal>
      Payout account(s) and the ability to take customer payments have been disabled.
      <br />
      <br />
      To restore access to your account or services, please make the required payment as
      soon as possible. Contact <a href={RESTORE_ACCESS_LINK}>billing@trycents.com</a> to
      urgently resolve this manner.
    </BaseBillingSuspensionModal>
  );
};
