import * as Sentry from "@sentry/react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {WebSocket} from "partysocket";
import ReconnectingWebSocket from "partysocket/ws";
import {useCallback, useEffect, useRef, useState} from "react";

import {WebsocketMessengerMessageType} from "~components/business-owner/messenger/constants";

import {useInvalidateEscalationCount} from "~api/business-owner/queries/messenger/useEscalationCount";
import {useWebSocket} from "~contexts/WebSocketContext";
import {WebSocketMessage} from "~types/messenger";
import {REACT_ENV, VITE_ENV_TYPES} from "~utils/config";

import {useInvalidateConversationList} from "./useConversationList";
import {useInvalidateConversationMessages} from "./useConversationMessages";
import useMessengerConnectionUrl from "./useMessengerConnectionUrl";
import {useThrottledInvalidateUnreadMessagesCount} from "./useUnreadMessagesCount";

const PING_INTERVAL = 420000; // 7 minutes
const MAX_RETRIES_WITH_UPDATED_URL = 5;
const RECONNECT_DELAY = 1000;

export const useMessengerWebSocket = () => {
  const {messengerAccelerate} = useFlags();
  const {getConnection, setConnection, getConnectionStatus} = useWebSocket();
  const [connectionStatus, setConnectionStatus] = useState<
    "connected" | "connecting" | "disconnected"
  >("disconnected");
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const retryCountRef = useRef<number>(0);
  const handleErrorRef = useRef<(error: Error) => void>();
  const isMountedRef = useRef(true);

  const {data: connectionData, refetch: refetchMessengerConnectionUrl} =
    useMessengerConnectionUrl();

  const invalidateConversationsMessages = useInvalidateConversationMessages();
  const scheduleInvalidateUnreadMessagesCount =
    useThrottledInvalidateUnreadMessagesCount();
  const invalidateEscalationCount = useInvalidateEscalationCount();
  const invalidateConversationsList = useInvalidateConversationList();

  useEffect(() => {
    if (connectionData?.url) {
      const status = getConnectionStatus(connectionData.url);
      setConnectionStatus(status);
    }
  }, [connectionData?.url, getConnectionStatus]);

  const clearPingInterval = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
  }, []);

  const startPingInterval = useCallback(
    (wsConnection: ReconnectingWebSocket) => {
      if (wsConnection?.url) {
        clearPingInterval();

        const channelId = new URL(wsConnection.url).searchParams.get("channelId");
        const pingMessage = {
          channelId,
          data: JSON.stringify({type: WebsocketMessengerMessageType.PING}),
        };

        pingIntervalRef.current = setInterval(() => {
          if (wsConnection?.readyState === WebSocket.OPEN) {
            wsConnection?.send(JSON.stringify(pingMessage));
          }
        }, PING_INTERVAL);
      }
    },
    [clearPingInterval]
  );

  const processWebsocketEvent = useCallback(
    (event: MessageEvent<string>) => {
      try {
        const {type}: WebSocketMessage = JSON.parse(event.data);
        const wsConnection = getConnection(connectionData?.url || "");

        if (wsConnection) {
          startPingInterval(wsConnection);
        }

        switch (type) {
          case WebsocketMessengerMessageType.INBOUND_SMS:
            invalidateConversationsList();
            invalidateConversationsMessages();
            scheduleInvalidateUnreadMessagesCount();
            break;
          case WebsocketMessengerMessageType.CONVERSATION_READ:
            scheduleInvalidateUnreadMessagesCount();
            invalidateConversationsList();
            break;
          case WebsocketMessengerMessageType.OUTBOUND_SMS:
            invalidateConversationsList();

            /**
             * Invalidate because it might be a bot response to an incoming message
             */
            invalidateEscalationCount();
            scheduleInvalidateUnreadMessagesCount();
            break;
          case WebsocketMessengerMessageType.SMS_STATUS_CALLBACK:
            invalidateConversationsList();
            invalidateConversationsMessages();
            break;
          case WebsocketMessengerMessageType.MESSAGE_DELETED:
            invalidateConversationsList();
            invalidateConversationsMessages();
            break;
          case WebsocketMessengerMessageType.RESEND_SMS:
            invalidateConversationsList();
            break;
          case WebsocketMessengerMessageType.CONVERSATION_RESOLVED:
            invalidateEscalationCount();
            invalidateConversationsList();
        }
      } catch (error) {
        console.error("Error processing websocket message:", error);
        Sentry.captureException(error);
      }
    },
    [
      startPingInterval,
      invalidateConversationsList,
      invalidateConversationsMessages,
      scheduleInvalidateUnreadMessagesCount,
      getConnection,
      connectionData?.url,
      invalidateEscalationCount,
    ]
  );

  const setupWebSocket = useCallback(
    (url: string) => {
      if (!isMountedRef.current || !url) {
        return;
      }

      const existingConnection = getConnection(url);
      if (existingConnection?.readyState === WebSocket.OPEN) {
        console.log("WebSocket connection already exists");
        return;
      }

      // Clean up existing connection if any
      setConnection(url, null);

      // Add small delay before reconnecting
      setTimeout(() => {
        if (!isMountedRef.current) {
          return;
        }

        const wsConnection = new WebSocket(url, [], {
          WebSocket: window.WebSocket,
          connectionTimeout: 4000,
          minReconnectionDelay: 4000,
          maxReconnectionDelay: 60000,
          maxEnqueuedMessages: 10,
          debug: REACT_ENV === VITE_ENV_TYPES.LOCAL,
        });

        wsConnection.onmessage = processWebsocketEvent;

        wsConnection.onerror = (errorEvent) => {
          if (wsConnection.readyState !== WebSocket.CLOSED) {
            const error = errorEvent.error || new Error(errorEvent.message);
            handleErrorRef.current?.(error);
          }
        };

        setConnection(url, wsConnection);
        startPingInterval(wsConnection);
      }, RECONNECT_DELAY);
    },
    [getConnection, setConnection, processWebsocketEvent, startPingInterval]
  );

  const handleConnectionError = useCallback(
    async (error: Error) => {
      console.error("WebSocket connection error:", error);

      const isSignatureError =
        error.message?.includes("INVALID_SIGNATURE") ||
        error.message?.includes("Signature expired");

      if (isSignatureError) {
        console.log("Refreshing connection URL due to signature expiration");
        retryCountRef.current = 0;
        const {data} = await refetchMessengerConnectionUrl();
        if (data?.url && isMountedRef.current) {
          setupWebSocket(data.url);
        }
      } else if (retryCountRef.current < MAX_RETRIES_WITH_UPDATED_URL) {
        retryCountRef.current += 1;
        const {data} = await refetchMessengerConnectionUrl();
        if (data?.url && isMountedRef.current) {
          setupWebSocket(data.url);
        }
      } else {
        Sentry.captureException(error);
      }
    },
    [refetchMessengerConnectionUrl, setupWebSocket]
  );

  handleErrorRef.current = handleConnectionError;

  useEffect(() => {
    isMountedRef.current = true;
    console.log(
      "[Messenger] Connection attempt with URL:",
      connectionData?.url,
      "messengerAccelerate:",
      messengerAccelerate
    );

    if (connectionData?.url && messengerAccelerate) {
      const existingConnection = getConnection(connectionData.url);
      if (!existingConnection || existingConnection.readyState !== WebSocket.OPEN) {
        console.log("[Messenger] Setting up new connection");
        setupWebSocket(connectionData.url);
      } else {
        console.log("[Messenger] Reusing existing connection");
      }
    }

    return () => {
      console.log("[Messenger] Cleanup effect");
      isMountedRef.current = false;
      if (connectionData?.url) {
        setConnection(connectionData.url, null);
      }
      clearPingInterval();
    };
  }, [
    connectionData?.url,
    messengerAccelerate,
    setupWebSocket,
    clearPingInterval,
    setConnection,
    getConnection,
  ]);

  return {connectionStatus};
};
