/**
 * When updating these values, make sure to update the DateRangeTypes enum in the backend
 * (src/constants/reports.ts) as well.
 */
export enum DateRangeTypes {
  CUSTOM = "CUSTOM",
  THIRTY_DAYS = "THIRTY_DAYS",
  THIS_MONTH = "THIS_MONTH",
  THIS_WEEK = "THIS_WEEK",
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  NEXT_SEVEN_DAYS = "NEXT_SEVEN_DAYS",
  YESTERDAY = "YESTERDAY",
  SEVEN_DAYS = "SEVEN_DAYS",
  PREVIOUS_WEEK = "PREVIOUS_WEEK",
  PREVIOUS_MONTH = "PREVIOUS_MONTH",
}

export const DATE_INPUT_PLACEHOLDER_DEFAULT = "mm/dd/yyyy";
