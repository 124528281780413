import {useCallback} from "react";

/**
 * List of custom event names.
 */
export enum CustomEventName {
  STORE_INVENTORY_UPDATED = "storeInventoryUpdated",
}

/**
 * Custom hook to manage custom events.
 * Provides methods to publish, subscribe, and unsubscribe to custom events.
 */
export const useCustomEvent = <EventDataType>(eventName: CustomEventName) => {
  const subscribe = useCallback(
    (listener) => {
      document.addEventListener(eventName, listener);
    },
    [eventName]
  );

  const unsubscribe = useCallback(
    (listener) => {
      document.removeEventListener(eventName, listener);
    },
    [eventName]
  );

  const publish = useCallback(
    (data: EventDataType) => {
      const event = new CustomEvent(eventName, {detail: data});
      document.dispatchEvent(event);
    },
    [eventName]
  );

  return {publish, subscribe, unsubscribe};
};
