import actionTypes from "actionTypes";
import {ErrorIcon} from "assets/images";
import {CustomModal} from "components/commons/CustomModal/CustomModal";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useIntercom} from "react-use-intercom";
import {sessionNamespacer} from "reducers/session";
import {SESSION_ENV_KEY} from "utils/config";

import "./BillingSuspensionBlockingModal.scss";

const RESTORE_ACCESS_LINK =
  "mailto:billing@trycents.com?subject=Suspended Account - Restore Access";

interface BaseBillingSuspensionModalProps {
  children: React.ReactNode;
}

export const BaseBillingSuspensionModal = ({
  children,
}: BaseBillingSuspensionModalProps) => {
  const dispatch = useDispatch();
  const {shutdown: shutdownIntercomChat} = useIntercom();

  const removeSession = useCallback(() => {
    localStorage.removeItem(SESSION_ENV_KEY);

    dispatch({
      type: sessionNamespacer(actionTypes.session.REMOVE_SESSION),
      payload: {
        value: false,
      },
    });
  }, [dispatch]);

  const Footer = (
    <span
      role="button"
      tabIndex={0}
      className="logout-btn"
      onClick={() => {
        shutdownIntercomChat();
        removeSession();
      }}
    >
      Logout
    </span>
  );

  return (
    <CustomModal
      isOpen
      titleIcon={ErrorIcon}
      title="Your account has been turned off due to non-payment."
      haveCloseButton={false}
      onSubmit={() => {
        window.open(RESTORE_ACCESS_LINK);
      }}
      submitText="Contact us now"
      footer={Footer}
    >
      <p className="blocking-modal-text">{children}</p>
    </CustomModal>
  );
};
