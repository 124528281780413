import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {withLDProvider} from "launchdarkly-react-client-sdk";
import React, {useEffect} from "react";
import {Toaster} from "react-hot-toast";
import {Provider} from "react-redux";
import {IntercomProvider} from "react-use-intercom";

import Routes from "~containers/routes";
import {initFullstory} from "~third-party/fullstory";
import {initSentryFullstory} from "~third-party/sentry";
// This is a side effect import that sets the MUI license key
import "~third-party/setMuiLicense";
import {INTERCOM_APP_ID, LAUNCHDARKLY_KEY, VITE_ENV_TYPES} from "~utils/config";

import {WebSocketProvider} from "./contexts/WebSocketContext";
import ErrorBoundary from "./ErrorBoundary";
import "./global.scss";
import store from "./store";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

if (![VITE_ENV_TYPES.TESTS, VITE_ENV_TYPES.LOCAL].includes(import.meta.env.VITE_ENV)) {
  initSentryFullstory();
} else {
  initFullstory();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // off initially to match apollo client - we can discuss enabling this later
      retry: 1,
    },
  },
});

const App = () => {
  useEffect(() => {
    const faviconEl = document.getElementById("favicon");
    if (faviconEl) {
      const env = import.meta.env.VITE_ENV;
      faviconEl.href = `/favicons/favicon-${env}.ico`;
    }
  }, []);

  return (
    <ErrorBoundary>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <QueryClientProvider client={queryClient}>
          <WebSocketProvider>
            <Elements stripe={stripePromise}>
              <Provider store={store}>
                <Routes />
                <Toaster position="bottom-center" reverseOrder={false} />
              </Provider>
            </Elements>
            {/* These devtools are only included when NODE_ENV === 'development' */}
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </WebSocketProvider>
        </QueryClientProvider>
      </IntercomProvider>
    </ErrorBoundary>
  );
};

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_KEY,
  context: {anonymous: true},
})(App);
