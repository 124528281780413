import * as Sentry from "@sentry/react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useEffect, useRef} from "react";

import {MessengerQueryKeys} from "~components/business-owner/messenger/constants";

import {fetchUnreadConversationsCount} from "~api/business-owner/messenger";
import {ApiError} from "~types/api";
import {callErrorToast} from "~utils/toasts";

const INVALIDATE_UNREAD_MESSAGES_COUNT_DELAY_DEFAULT_MS = 1000;
const RESET_FIRST_REQUEST_SENT_TIMER_DELAY_DEFAULT_MS = 1000;

export interface UseUnreadMessagesCountHookResult {
  unreadConversationsCount: number;
  isUnreadConversationsCountLoading: boolean;
  unreadConversationsCountError: ApiError | null;
  refetchUnreadConversationsCount: () => void;
}

export const useInvalidateUnreadMessagesCount = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries([MessengerQueryKeys.UNREAD_CONVERSATIONS_COUNT]);
};

export const useThrottledInvalidateUnreadMessagesCount = () => {
  const invalidateUnreadMessagesCount = useInvalidateUnreadMessagesCount();
  const invalidateUnreadMessagesCountTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const resetFirstRequestSentTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isFirstRequestSent = useRef(false);

  useEffect(() => {
    return () => {
      if (invalidateUnreadMessagesCountTimeoutRef.current) {
        clearTimeout(invalidateUnreadMessagesCountTimeoutRef.current);
      }

      if (resetFirstRequestSentTimeoutRef.current) {
        clearTimeout(resetFirstRequestSentTimeoutRef.current);
      }
    };
  }, []);

  return () => {
    if (!isFirstRequestSent.current) {
      invalidateUnreadMessagesCount();
      isFirstRequestSent.current = true;

      // Resets only if there's no call for 1 second. Otherwise we clear timeout
      resetFirstRequestSentTimeoutRef.current = setTimeout(() => {
        isFirstRequestSent.current = false;
      }, RESET_FIRST_REQUEST_SENT_TIMER_DELAY_DEFAULT_MS);

      return;
    }

    if (invalidateUnreadMessagesCountTimeoutRef.current) {
      return;
    }

    if (resetFirstRequestSentTimeoutRef.current) {
      clearTimeout(resetFirstRequestSentTimeoutRef.current);
    }

    invalidateUnreadMessagesCountTimeoutRef.current = setTimeout(() => {
      invalidateUnreadMessagesCount();

      invalidateUnreadMessagesCountTimeoutRef.current = null;
      isFirstRequestSent.current = false;
    }, INVALIDATE_UNREAD_MESSAGES_COUNT_DELAY_DEFAULT_MS);
  };
};

export const useUnreadMessagesCount = (): UseUnreadMessagesCountHookResult => {
  const {messengerAccelerate} = useFlags();

  const {
    data: unreadConversationsCount,
    isLoading: isUnreadConversationsCountLoading,
    error: unreadConversationsCountError,
    refetch: refetchUnreadConversationsCount,
  } = useQuery<unknown, ApiError, number>({
    queryKey: [MessengerQueryKeys.UNREAD_CONVERSATIONS_COUNT],
    queryFn: async () => {
      const {data} = await fetchUnreadConversationsCount();

      return data?.count;
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({
        content: "An error occurred while loading unread conversations count",
      });
    },
    enabled: !!messengerAccelerate,
    staleTime: Infinity,
  });

  return {
    unreadConversationsCount: unreadConversationsCount || 0,
    isUnreadConversationsCountLoading,
    unreadConversationsCountError,
    refetchUnreadConversationsCount,
  };
};
