import momenttz from "moment-timezone";

import {ORIGIN_TYPES} from "~constants/statuses/originTypes";
import {ConversationsListSearchParamsType} from "~reducers/businessOwner/messenger";
import {MessengerMessage} from "~types/messenger";
import {extractDate} from "~utils/dates";
import {formatDate} from "~utils/formatters/date";

import {MessengerFilterTabsNames, MessengerQueryKeys} from "./constants";

function getTabNameWithCount(tabName: string, count: number) {
  return `${tabName} ${count > 0 ? `(${count})` : ""}`;
}

export const evaluateFilterTabName = ({
  tabName,
  unreadConversationsCount,
  escalationCount,
}: {
  tabName: MessengerFilterTabsNames;
  unreadConversationsCount: number;
  escalationCount: number;
}) => {
  switch (tabName) {
    case MessengerFilterTabsNames.All:
      return tabName;
    case MessengerFilterTabsNames.UNREAD:
      return getTabNameWithCount(tabName, unreadConversationsCount);
    case MessengerFilterTabsNames.ESCALATIONS:
      return getTabNameWithCount(tabName, escalationCount);
  }
};

export const getConversationDateString = (date: string): string => {
  const timeZone = momenttz.tz.guess();
  const today = momenttz();
  const isDateToday = momenttz.tz(date, timeZone).isSame(today, "day");

  return isDateToday ? formatDate(date, "h:mm a") : formatDate(date, "MMM D");
};

export const getCallDateString = (date: string): string => {
  const timeZone = momenttz.tz.guess();
  const today = momenttz();
  const isDateToday = momenttz.tz(date, timeZone).isSame(today, "day");

  return isDateToday ? formatDate(date, "h:mm a") : formatDate(date, "MMM D, h:mm a");
};

export const getPagesConfiguration = ({
  currentPage,
  perPageLimit,
  totalItems,
}: {
  currentPage: number;
  perPageLimit: number;
  totalItems: number;
}): {
  from: number;
  to: number;
  totalPages: number;
} => {
  const totalPages = Math.ceil(totalItems / perPageLimit);

  if (totalPages === 0) {
    return {
      from: 0,
      to: 0,
      totalPages: 0,
    };
  }

  const from = (currentPage - 1) * perPageLimit + 1;
  const to =
    currentPage * perPageLimit >= totalItems ? totalItems : currentPage * perPageLimit;

  return {
    from,
    to,
    totalPages,
  };
};

export const getFirstLetters = (name: string): string => {
  return name
    .split(" ")
    .map((word) => word[0])
    .join("");
};

export const getTimeAgoFromNow = (date: string): string => {
  const timeZone = momenttz.tz.guess();
  const tzDate = momenttz.tz(date, timeZone);
  return tzDate.fromNow();
};

export const getConversationsListQueryKey = ({
  page,
  q,
  unread,
  isEscalated,
}: Partial<ConversationsListSearchParamsType>) => [
  MessengerQueryKeys.CONVERSATIONS_LIST,
  page,
  q,
  unread,
  isEscalated,
];

export const getMessageSentTime = (date: string): string => {
  return formatDate(date, "h:mma");
};

export const groupMessagesByDay = (
  messages: MessengerMessage[]
): Map<string, {date: string; messages: MessengerMessage[]}> => {
  const groupedMessages = new Map<string, {date: string; messages: MessengerMessage[]}>();
  const timeZone = momenttz.tz.guess();
  const today = momenttz();

  messages.forEach((message) => {
    const groupKey = extractDate(message.created);
    const isDateToday = momenttz.tz(message.created, timeZone).isSame(today, "day");
    const groupDate = isDateToday ? "Today" : formatDate(message.created, "ddd, MMM D");

    if (!groupedMessages.has(groupKey)) {
      groupedMessages.set(groupKey, {
        date: groupDate,
        messages: [],
      });
    }

    groupedMessages.get(groupKey)?.messages.push(message);
  });

  return groupedMessages;
};

export const getMessageOriginLabel = (origin?: ORIGIN_TYPES): string | null => {
  const ORIGIN_LABELS = {
    [ORIGIN_TYPES.BUSINESS_MANAGER]: "Business Manager",
    [ORIGIN_TYPES.DRIVER_APP]: "Driver App",
    [ORIGIN_TYPES.EMPLOYEE_APP]: "Employee App",
    [ORIGIN_TYPES.BOT]: "Cents AI",
    [ORIGIN_TYPES.POCKETFLOWS]: "Campaigns",
  };

  return origin ? `Sent from ${ORIGIN_LABELS[origin]}` : null;
};
