import {useFlags, useLDClient} from "launchdarkly-react-client-sdk";
import React, {useCallback, useEffect, useMemo} from "react";
import {Route} from "react-router-dom";

import {BillingSuspension90DaysBlockingModal} from "~components/commons/BillingSuspensionBlockingModal/BillingSuspension90DaysBlockingModal";
import {BillingSuspensionBlockingModal} from "~components/commons/BillingSuspensionBlockingModal/BillingSuspensionBlockingModal";

import {SESSION_ENV_KEY, VITE_ENV_TYPES} from "~utils/config";
import {getParsedLocalStorageData} from "~utils/functions";

const env = import.meta.env.VITE_ENV;

/*
  This wrapped Route is used to identify LD user 
  and to display BillingSuspensionBlockingModal if "billingAccountSuspendedModal" feature flag is enabled
  or BillingSuspension90DaysBlockingModal if "billingAccountSuspended90Days" feature flag is enabled
*/
const RouteWithLDUser = ({component: Component, ...rest}) => {
  const {billingAccountSuspendedModal, billingAccountSuspended90Days} = useFlags();
  const LDClient = useLDClient();
  const localStorageData = useMemo(() => getParsedLocalStorageData(SESSION_ENV_KEY), []);

  const registerLaunchDarklyUser = useCallback(() => {
    const user = {
      key: localStorageData?.email,
      email: localStorageData?.email,
      custom: {businessId: localStorageData?.business?.id},
    };

    void LDClient?.identify(user);
  }, [LDClient, localStorageData]);

  useEffect(() => {
    registerLaunchDarklyUser();
  }, [registerLaunchDarklyUser]);

  let ComponentToRender = Component;

  if (env !== VITE_ENV_TYPES.TESTS) {
    if (billingAccountSuspended90Days) {
      ComponentToRender = BillingSuspension90DaysBlockingModal;
    } else if (billingAccountSuspendedModal) {
      ComponentToRender = BillingSuspensionBlockingModal;
    }
  }

  return <Route {...rest} component={ComponentToRender} />;
};

export default RouteWithLDUser;
