import moment from "moment";

import {DateRangeTypes} from "~constants/dates/dateRange";

interface TimeData {
  time: string;
  [key: string]: string | number;
}

/**
 * When updating this function, make sure to also update the corresponding logic
 * in the backend (`src/utils/reports/getDateRangeForDateRangeType.ts`).
 */
export const getDateRangeForDateRangeType = ({
  dateRangeType,
}: {
  dateRangeType: DateRangeTypes;
}): {
  startDate: moment.Moment;
  endDate: moment.Moment;
} => {
  const today = moment();
  const yesterday = today.clone().subtract(1, "days");

  switch (dateRangeType) {
    case DateRangeTypes.THIRTY_DAYS: {
      return {
        startDate: today.clone().subtract(30, "days"),
        endDate: yesterday,
      };
    }
    case DateRangeTypes.THIS_MONTH: {
      return {
        startDate: today.clone().startOf("month"),
        endDate: today,
      };
    }
    case DateRangeTypes.THIS_WEEK: {
      return {
        startDate: today.clone().startOf("week"),
        endDate: today,
      };
    }
    case DateRangeTypes.TODAY: {
      return {
        startDate: today.clone().startOf("day"),
        endDate: today.clone().endOf("day"),
      };
    }
    case DateRangeTypes.TOMORROW: {
      return {
        startDate: today.clone().add(1, "days").startOf("day"),
        endDate: today.clone().add(1, "days").endOf("day"),
      };
    }
    case DateRangeTypes.NEXT_SEVEN_DAYS: {
      return {
        startDate: today.clone().add(1, "days").startOf("day"),
        endDate: today.clone().add(7, "days").endOf("day"),
      };
    }
    case DateRangeTypes.YESTERDAY: {
      return {
        startDate: today.clone().subtract(1, "days").startOf("day"),
        endDate: today.clone().subtract(1, "days").endOf("day"),
      };
    }
    case DateRangeTypes.SEVEN_DAYS: {
      return {
        startDate: today.clone().subtract(7, "days"),
        endDate: yesterday,
      };
    }
    case DateRangeTypes.PREVIOUS_WEEK: {
      const startDate = today.clone().subtract(1, "week").startOf("week");
      return {
        startDate,
        endDate: startDate.clone().endOf("week"),
      };
    }
    case DateRangeTypes.PREVIOUS_MONTH: {
      const startDate = today.clone().subtract(1, "month").startOf("month");
      return {
        startDate,
        endDate: startDate.clone().endOf("month"),
      };
    }
    default: {
      return {
        startDate: today.clone().subtract(30, "days"),
        endDate: today,
      };
    }
  }
};

export const extractDate = (isoString: string) => {
  return moment(isoString).format("YYYY-MM-DD");
};

export const extractTime = (isoString: string) => {
  return moment(isoString).format("HH:mm:ss");
};

export const combineDateTimeToISO = (date: string, time: string) => {
  return moment(`${date}T${time}`).toISOString();
};

export const convertMsToHours = (ms: number) => {
  return Math.floor(ms / 1000 / 60 / 60);
};

export const convertHoursToMs = (hours: number) => {
  return hours * 60 * 60 * 1000;
};

export const getDefaultDashboardFilterPeriod = () => {
  const today = moment();
  return {
    type: DateRangeTypes.TODAY,
    endDate: today.clone().endOf("day"),
    startDate: today.clone().startOf("day"),
  };
};

/**
 * Helper to group data by a given key.
 * It sums all numeric properties for each group (ignoring the "time" property).
 */
const groupHelper = (
  grouped: Record<string, TimeData>,
  groupKey: string,
  item: TimeData
): void => {
  if (!grouped[groupKey]) {
    // Initialize with the group key as the "time" field.
    grouped[groupKey] = {time: groupKey};
  }
  // For every key in item (except "time"), add its numeric value.
  Object.keys(item).forEach((key) => {
    if (key === "time") {
      return;
    }

    // Convert the value to a number. If it's not a number, treat it as 0.
    const value =
      typeof item[key] === "number" ? (item[key] as number) : Number(item[key]);
    grouped[groupKey][key] = ((grouped[groupKey][key] as number) || 0) + value;
  });
};

/**
 * Sorts an array of TimeData objects by their "time" property.
 * Assumes the time is in the format "MM-DD-YYYY".
 */
const sortData = (data: TimeData[]): TimeData[] => {
  return data.slice().sort((a, b) => {
    const dateA = moment(a.time, "MM-DD-YYYY");
    const dateB = moment(b.time, "MM-DD-YYYY");
    return dateA.diff(dateB);
  });
};

/**
 * Groups data into weeks (7-day chunks) after sorting.
 *
 * The data here is formatted into MM-DD for cleanliness
 */
const groupByWeek = (data: TimeData[]): TimeData[] => {
  const newData: TimeData[] = [];
  const sortedData = sortData(data);

  const numWeeks = Math.ceil(sortedData.length / 7);

  // grouping via week chunks
  for (let week = 0; week < numWeeks; week++) {
    const startIdx = week * 7;
    const endIdx = Math.min(startIdx + 7, sortedData.length);
    const weekData = sortedData.slice(startIdx, endIdx);

    // creating a group label from the first and last date in this chunk.
    // setting labels without the year here because it looks cleaner
    const weekLabel = `${moment(weekData[0].time).format("MM-DD")} - ${moment(
      weekData[weekData.length - 1].time
    ).format("MM-DD")}`;
    const grouped: Record<string, TimeData> = {};
    weekData.forEach((item) => {
      groupHelper(grouped, weekLabel, item);
    });

    // There should be exactly one group with key weekLabel.
    newData.push(grouped[weekLabel]);
  }
  return newData;
};

/**
 * Groups data by month.
 * Each group key is the month formatted as "MMM YYYY" (e.g., "Feb 2025").
 */
const groupByMonth = (data: TimeData[]): TimeData[] => {
  const groups: Record<string, TimeData> = {};
  const sortedData = sortData(data);

  sortedData.forEach((item) => {
    const monthKey = moment(item.time, "MM-DD-YYYY").format("MMM YYYY");
    groupHelper(groups, monthKey, item);
  });

  // Sort groups by date.
  const sortedKeys = Object.keys(groups).sort((a, b) => {
    return moment(a, "MMM YYYY").diff(moment(b, "MMM YYYY"));
  });
  return sortedKeys.map((key) => groups[key]);
};

/**
 * Group and sort dashboard data by time
 *
 * If the total length of data is less than eo (meaning 30 days or less or 24 hours or less), return data sorted from oldest to newest.
 * If more than 30 days but 90 days or less, group the data by weeks
 * If more than 90 days, group the data by months
 */
export const groupDataByTime = (data: TimeData[]): TimeData[] => {
  if (data.length === 0) {
    return data;
  }

  if (data.length <= 30) {
    return data.sort((a, b) => moment(a.time).diff(moment(b.time)));
  } else if (data.length > 90) {
    return groupByMonth(data);
  } else {
    return groupByWeek(data);
  }
};
