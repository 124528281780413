import ReconnectingWebSocket from "partysocket/ws";
import React, {createContext, useCallback, useContext, useMemo, useRef} from "react";

interface WebSocketContextType {
  getConnection: (endpoint: string) => ReconnectingWebSocket | null;
  setConnection: (endpoint: string, connection: ReconnectingWebSocket | null) => void;
  getConnectionStatus: (endpoint: string) => "connected" | "connecting" | "disconnected";
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

// Move outside component to prevent recreation
const connections = new Map<string, ReconnectingWebSocket | null>();
const connectionStatuses = new Map<string, "connected" | "connecting" | "disconnected">();

export const WebSocketProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const statusListenersRef = useRef(new Set<() => void>());

  const notifyStatusListeners = useCallback(() => {
    statusListenersRef.current.forEach((listener) => listener());
  }, []);

  const getConnection = useCallback((endpoint: string) => {
    return connections.get(endpoint) || null;
  }, []);

  const getConnectionStatus = useCallback((endpoint: string) => {
    return connectionStatuses.get(endpoint) || "disconnected";
  }, []);

  const setConnection = useCallback(
    (endpoint: string, connection: ReconnectingWebSocket | null) => {
      if (connection) {
        connections.set(endpoint, connection);
        connectionStatuses.set(endpoint, "connecting");
        notifyStatusListeners();

        connection.onopen = () => {
          connectionStatuses.set(endpoint, "connected");
          notifyStatusListeners();
        };

        connection.onclose = () => {
          connectionStatuses.set(endpoint, "disconnected");
          notifyStatusListeners();
        };
      } else {
        const existingConnection = connections.get(endpoint);
        if (existingConnection) {
          existingConnection.close(1000);
        }
        connections.delete(endpoint);
        connectionStatuses.set(endpoint, "disconnected");
        notifyStatusListeners();
      }
    },
    [notifyStatusListeners]
  );

  const contextValue = useMemo(
    () => ({
      getConnection,
      setConnection,
      getConnectionStatus,
    }),
    [getConnection, setConnection, getConnectionStatus]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
