import * as Sentry from "@sentry/react";
import {useQuery, useQueryClient} from "@tanstack/react-query";

import {MessengerQueryKeys} from "~components/business-owner/messenger/constants";

import {fetchEscalationCount} from "~api/business-owner/messenger";
import {ApiError} from "~types/api";
import {callErrorToast} from "~utils/toasts";

export interface UseEscalationCountHookResult {
  escalationCount: number;
  isEscalationCountLoading: boolean;
  escalationCountError: ApiError | null;
  refetchEscalationCount: () => void;
}

export const useInvalidateEscalationCount = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries([MessengerQueryKeys.ESCALATION_COUNT]);
};

export const useEscalationCount = (): UseEscalationCountHookResult => {
  const {
    data: escalationCount,
    isLoading: isEscalationCountLoading,
    error: escalationCountError,
    refetch: refetchEscalationCount,
  } = useQuery<unknown, ApiError, number>({
    queryKey: [MessengerQueryKeys.ESCALATION_COUNT],
    queryFn: async () => {
      const {data} = await fetchEscalationCount();

      return data?.count;
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({
        content: "An error occurred while loading escalation count",
      });
    },
  });

  return {
    escalationCount: escalationCount || 0,
    isEscalationCountLoading,
    escalationCountError,
    refetchEscalationCount,
  };
};
