export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;
export const REACT_ENV = import.meta.env.VITE_ENV;
export const VITE_LIVE_LINK_URL = import.meta.env.VITE_LIVE_LINK_URL;
export const PUSHER_APP_CLUSTER = import.meta.env.VITE_PUSHER_APP_CLUSTER;
export const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY;
export const LAUNCHDARKLY_KEY = import.meta.env.VITE_LAUNCHDARKLY_KEY;
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
export const GOOGLE_PLACE_API_KEY = import.meta.env.VITE_GOOGLE_PLACE_API_KEY;
export const MAXIO_PUBLIC_KEY = import.meta.env.VITE_MAXIO_PUBLIC_KEY;
export const MAXIO_HOST = import.meta.env.VITE_MAXIO_HOST;
export const FULLSTORY_ORG_ID = "o-1KH4JQ-na1";
export const SENTRY_ORG_SLUG = "make-cents-technologies";
export const CLOUDFLARE_TURNSTILE_SITE_KEY = import.meta.env
  .VITE_CLOUDFLARE_TURNSTILE_SITE_KEY;

export const SESSION_ENV_KEY = `ADMIN_TRYCENTS:${(
  REACT_ENV || "dev"
).toUpperCase()}:SESSION`;

export const VITE_ENV_TYPES = {
  DEV: "dev",
  LOCAL: "local",
  PRE_PROD: "pre-prod",
  PRODUCTION: "production",
  TESTS: "e2e-tests",
};
