import * as Sentry from "@sentry/react";
import {WebSocket} from "partysocket";
import ReconnectingWebSocket from "partysocket/ws";
import {useCallback, useEffect, useRef, useState} from "react";

import {CustomEventName, useCustomEvent} from "~hooks/useCustomEvent";

import {useWebSocket} from "~contexts/WebSocketContext";
import {REACT_ENV, VITE_ENV_TYPES} from "~utils/config";

import {useQtyUpdaterConnectionUrl} from "./useQtyUpdaterConnectionUrl";

// WS API has idle limit, see: https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#apigateway-execution-service-websocket-limits-table
const PING_INTERVAL_MS = 7 * 60 * 1000; // 7 minutes
const MAX_RETRIES_WITH_UPDATED_URL = 5;
const RECONNECT_DELAY = 1000;

enum QtyUpdaterEventTypes {
  PING = "ping",
  INVENTORY_UPDATED = "inventoryUpdated",
}

export interface EventData {
  type: QtyUpdaterEventTypes;
}

export interface InventoryUpdatedEventData extends EventData {
  type: QtyUpdaterEventTypes.INVENTORY_UPDATED;
  storeId: number;
  inventoryId: number;
  quantity: number;
}

export const useQtyUpdaterWebSocket = () => {
  const {getConnection, setConnection, getConnectionStatus} = useWebSocket();
  const [connectionStatus, setConnectionStatus] = useState<
    "connected" | "connecting" | "disconnected"
  >("disconnected");
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const retryCountRef = useRef<number>(0);
  const handleErrorRef = useRef<(error: Error) => void>();
  const isMountedRef = useRef(true);

  const {publish} = useCustomEvent(CustomEventName.STORE_INVENTORY_UPDATED);
  const {data: connectionData, refetch: refetchConnectionUrl} =
    useQtyUpdaterConnectionUrl();

  useEffect(() => {
    if (connectionData?.url) {
      const status = getConnectionStatus(connectionData.url);
      setConnectionStatus(status);
    }
  }, [connectionData?.url, getConnectionStatus]);

  const clearPingInterval = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
  }, []);

  const startPingInterval = useCallback(
    (wsConnection: ReconnectingWebSocket) => {
      if (wsConnection?.url) {
        clearPingInterval();

        const channelId = new URL(wsConnection.url).searchParams.get("channelId");
        const pingMessage = {
          channelId,
          data: JSON.stringify({type: QtyUpdaterEventTypes.PING}),
        };

        pingIntervalRef.current = setInterval(() => {
          if (wsConnection?.readyState === WebSocket.OPEN) {
            wsConnection?.send(JSON.stringify(pingMessage));
          }
        }, PING_INTERVAL_MS);
      }
    },
    [clearPingInterval]
  );

  const processWebsocketEvent = useCallback(
    (event: MessageEvent<string>) => {
      try {
        const parsedData: EventData = JSON.parse(event.data);
        const wsConnection = getConnection(connectionData?.url || "");

        if (wsConnection) {
          startPingInterval(wsConnection);
        }

        if (parsedData.type === QtyUpdaterEventTypes.INVENTORY_UPDATED) {
          publish(parsedData as InventoryUpdatedEventData);
        }
      } catch (error) {
        console.error("Error processing websocket message:", error);
        Sentry.captureException(error);
      }
    },
    [startPingInterval, publish, getConnection, connectionData?.url]
  );

  const setupWebSocket = useCallback(
    (url: string) => {
      if (!isMountedRef.current || !url) {
        return;
      }

      const existingConnection = getConnection(url);
      if (existingConnection?.readyState === WebSocket.OPEN) {
        console.log("WebSocket connection already exists");
        return;
      }

      // Clean up existing connection if any
      setConnection(url, null);

      // Add small delay before reconnecting
      setTimeout(() => {
        if (!isMountedRef.current) {
          return;
        }

        const wsConnection = new WebSocket(url, [], {
          WebSocket: window.WebSocket,
          connectionTimeout: 4000,
          minReconnectionDelay: 4000,
          maxReconnectionDelay: 60000,
          maxEnqueuedMessages: 10,
          debug: REACT_ENV === VITE_ENV_TYPES.LOCAL,
        });

        wsConnection.onmessage = processWebsocketEvent;

        wsConnection.onerror = (errorEvent) => {
          if (wsConnection.readyState !== WebSocket.CLOSED) {
            const error = errorEvent.error || new Error(errorEvent.message);
            handleErrorRef.current?.(error);
          }
        };

        setConnection(url, wsConnection);
        startPingInterval(wsConnection);
      }, RECONNECT_DELAY);
    },
    [getConnection, setConnection, processWebsocketEvent, startPingInterval]
  );

  const handleConnectionError = useCallback(
    async (error: Error) => {
      console.error("WebSocket connection error:", error);

      if (retryCountRef.current < MAX_RETRIES_WITH_UPDATED_URL) {
        retryCountRef.current += 1;
        const {data} = await refetchConnectionUrl();
        if (data?.url && isMountedRef.current) {
          setupWebSocket(data.url);
        }
      } else {
        Sentry.captureException(error);
      }
    },
    [refetchConnectionUrl, setupWebSocket]
  );

  handleErrorRef.current = handleConnectionError;

  useEffect(() => {
    isMountedRef.current = true;
    console.log("[QtyUpdater] Connection attempt with URL:", connectionData?.url);

    if (connectionData?.url) {
      const existingConnection = getConnection(connectionData.url);
      if (!existingConnection || existingConnection.readyState !== WebSocket.OPEN) {
        console.log("[QtyUpdater] Setting up new connection");
        setupWebSocket(connectionData.url);
      } else {
        console.log("[QtyUpdater] Reusing existing connection");
      }
    }

    return () => {
      console.log("[QtyUpdater] Cleanup effect");
      isMountedRef.current = false;
      if (connectionData?.url) {
        setConnection(connectionData.url, null);
      }
      clearPingInterval();
    };
  }, [
    connectionData?.url,
    setupWebSocket,
    clearPingInterval,
    setConnection,
    getConnection,
  ]);

  return {connectionStatus};
};
