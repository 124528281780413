import * as Sentry from "@sentry/react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useEffect, useMemo, useState} from "react";

import {getConversationsListQueryKey} from "~components/business-owner/messenger/utils";

import {useConversationsListSearchParams} from "~hooks/messenger/useConversationsListSearchParams";

import {
  fetchConversations,
  FetchConversationsParams,
  FetchConversationsResponse,
} from "~api/business-owner/messenger";
import {ApiError} from "~types/api";
import {MessengerConversationItem} from "~types/messenger";
import {callErrorToast} from "~utils/toasts";

export interface UseConversationListHookResult {
  isConversationsLoading: boolean;
  isFetching: boolean;
  conversationsList: MessengerConversationItem[];
  hasNextPage: boolean;
  total: number;
  currentPage: number;
}

export const useInvalidateConversationList = () => {
  const queryClient = useQueryClient();
  const params = useConversationsListSearchParams();

  return () => queryClient.invalidateQueries(getConversationsListQueryKey(params));
};

export const useConversationList = ({
  page,
  perPage,
  q,
  unread,
  isEscalated,
}: FetchConversationsParams): UseConversationListHookResult => {
  const [localSearchTerm, setLocalSearchTerm] = useState(q);

  useEffect(
    function debounceTextSearch() {
      const timeout = setTimeout(() => {
        setLocalSearchTerm(q);
      }, 300);
      return () => clearTimeout(timeout);
    },
    [q]
  );

  const {data, isFetching, isInitialLoading} = useQuery<
    FetchConversationsResponse | null,
    ApiError
  >({
    queryKey: getConversationsListQueryKey({
      page,
      q: localSearchTerm,
      unread,
      isEscalated,
    }),
    queryFn: async () => {
      const {data} = await fetchConversations({
        params: {
          page,
          perPage,
          q: localSearchTerm?.trim() || undefined,
          unread,
          isEscalated,
        },
      });

      return data ?? {};
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({content: "An error occurred while loading conversations"});
    },
  });

  const conversationsList = useMemo(
    () => data?.records?.map((message) => message) ?? [],
    [data]
  );

  return {
    isConversationsLoading: isInitialLoading,
    conversationsList: conversationsList,
    isFetching,
    hasNextPage: data?.meta?.hasNextPage || false,
    total: data?.meta?.total || 0,
    currentPage: data?.meta?.currentPage || 1,
  };
};
