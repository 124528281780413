import React from "react";

import {BaseBillingSuspensionModal} from "./BaseBillingSuspensionModal";

const RESTORE_ACCESS_LINK =
  "mailto:billing@trycents.com?subject=Suspended Account - Restore Access";

export const BillingSuspensionBlockingModal = () => {
  return (
    <BaseBillingSuspensionModal>
      Payout account(s) have been paused.
      <br />
      <br />
      To restore access to your account or services, please make the required payment as
      soon as possible. Contact <a href={RESTORE_ACCESS_LINK}>billing@trycents.com</a> to
      urgently resolve this matter.
    </BaseBillingSuspensionModal>
  );
};
